import React from 'react';
import styled from 'styled-components';
import Footer from '../../components/home/v3/Footer';
import MenuBar from '../../components/home/MenuBar';
import Events from '../../components/home/Events';
import { colors, Container as ContainerV3, Content } from '../../components/home/v3/styledComponents';
import Layout from '../../layouts/index';


const Container = styled(ContainerV3)`
  background-color: ${colors.white};
`;

class EventsPage extends React.Component {
  componentDidMount() {
    if (typeof window === 'undefined') return;
    window.scrollTo(0, 0);
    window.onbeforeunload = null;
  }

  render() {
    return (
      <Layout routeSlug="EventsPage">
        <Container>
          <MenuBar />
          <Content>
            <Events />
          </Content>
          <Footer />
        </Container>
      </Layout>
    );
  }
}

export default EventsPage;
