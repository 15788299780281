import React from 'react';
import styled from 'styled-components';
import imageAdresseParis1 from '../../assets/events/AdresseParis.1.jpg';
import imageAdresseParis2 from '../../assets/events/AdresseParis.2.jpg';
import imageAubade1 from '../../assets/events/Aubade.1.jpg';
import imageAubade2 from '../../assets/events/Aubade.2.jpg';
import imageBHVMarais1 from '../../assets/events/BHVMarais.1.jpg';
import imageBHVMarais2 from '../../assets/events/BHVMarais.2.jpg';
import imageGaleriesLafayetteChampsElysees1 from '../../assets/events/GaleriesLafayetteChampsElysees.1.jpg';
import imagePrintempsMariage1 from '../../assets/events/PrintempsMariage.1.jpg';
import imagePrintempsMariage2 from '../../assets/events/PrintempsMariage.2.jpg';
import imageSlipFrancais1 from '../../assets/events/SlipFrancais.1.jpg';
import imageSlipFrancais2 from '../../assets/events/SlipFrancais.2.jpg';
import imageTheSocialiteFamily1 from '../../assets/events/TheSocialiteFamily.1.jpg';
import imageTheSocialiteFamily2 from '../../assets/events/TheSocialiteFamily.2.jpg';
import imageImpact1 from '../../assets/events/Impact.1.jpg';
import imageImpact2 from '../../assets/events/Impact.2.jpg';
import { Button, colors, mobileThresholdPixels } from './v3/styledComponents';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${colors.navy};
  box-sizing: border-box;
  padding: 149px 97px 140px;
  max-width: 1440px;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 52px 25px 50px;
    max-width: unset;
    width: 100%;
  }
`;

const Title = styled.div`
  font-family: Libre Baskerville;
  font-weight: bold;
  font-size: 48px;
  line-height: 62px;
  text-align: center;
  max-width: 580px;
  margin-bottom: 30px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 25px;
    line-height: 30px;
    padding: 0px 22px;
    margin-bottom: 10px;
  }
`;

const Line = styled.div`
  width: 92px;
  height: 3px;
  background-color: ${colors.lightGrey2};
  margin-bottom: 87px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 38px;
    margin-bottom: 45px;
  }
`;

const Text = styled.div`
  font-family: Roboto;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  margin-bottom: ${({ isBottomText }) => isBottomText ? 79 : 138}px;
  padding: 0px ${({ isBottomText }) => isBottomText ? 150 : 70}px;
  ${({ isBottomText }) => isBottomText && 'margin-top: -20px;'}

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 12px;
    line-height: 17px;
    margin-bottom: ${({ isBottomText }) => isBottomText ? 25 : 59}px;
    padding: 0px;
    ${({ isBottomText }) => isBottomText && 'margin-top: -6px;'}
  }
`;

const EventContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const EventTitle = styled.div`
  font-family: Libre Baskerville;
  font-weight: ${({ isSubTitle }) => isSubTitle ? 300 : 'bold'};
  font-size: ${({ isSubTitle }) => isSubTitle ? 26 : 32}px;
  line-height: ${({ isSubTitle }) => isSubTitle ? 36 : 42}px;
  ${({ isSubTitle }) => isSubTitle && 'margin-bottom: 50px;'}

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${({ isSubTitle }) => isSubTitle ? 12 : 15}px;
    line-height: ${({ isSubTitle }) => isSubTitle ? 17 : 20}px;
    ${({ isSubTitle }) => isSubTitle && 'margin-bottom: 21px;'}
  }
`;

const EventImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 45px;

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: ${({ mobileReversed }) => mobileReversed ? 'column-reverse' : 'column'};
    justify-content: center;
    margin-bottom: 10px;
    max-height: unset;
  }
`;

const EventImg = styled.img`
  ${({ hasMarginLeft }) => hasMarginLeft && 'margin-left: 33px;'}
  max-height: ${({ height }) => height}px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
    height: auto;
    margin-left: 0px;
    margin-bottom: 19px;
  }
`;

const EventText = styled.div`
  width: 100%;
  ${({ isSubText }) => !isSubText && `background-color: ${colors.lightGrey};`}
  padding: ${({ isSubText }) => isSubText ? 0 : 52}px 50px;
  ${({ isSubText }) => isSubText && 'font-style: italic;'}
  font-family: Roboto;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  margin-bottom: ${({ isSubText, hasSubText }) => {
    if (isSubText) return 78;
    return hasSubText ? 52 : 120;
  }}px;
  box-sizing: border-box;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 12px;
    line-height: 17px;
    padding: ${({ isSubText }) => isSubText ? 0 : 22}px 25px;
    margin-bottom: ${({ isSubText, hasSubText }) => {
    if (isSubText) return 54;
    return hasSubText ? 21 : 29;
  }}px;
  }
`;

const A = styled.a`
  text-decoration: underline;
  color: ${colors.navy};
`;

const events = [
  {
    title: 'Impact (Salon Who’s Next) - 17 au 20 janv',
    subTitle: 'Popup et conférences',
    imagesHeight: 485,
    images: [imageImpact1, imageImpact2],
    // eslint-disable-next-line max-len
    text: 'Tilli rejoint une nouvelle fois les enseignes éco-responsables du salon IMPACT (Who’s Next). Quatre jours stimulants d’échanges, de rencontres et de conférences autour de la mode de demain.',
  },
  {
    title: 'Aubade (rue Tronchet) - 21 déc',
    subTitle: 'Atelier personnalisation sur lingerie',
    imagesHeight: 437,
    images: [imageAubade1, imageAubade2],
    // eslint-disable-next-line max-len
    text: 'Le samedi 21 décembre, une Tilliste sera présente dans la boutique Aubade située rue Tronchet dans le 8ème arrondissement de Paris pour personnaliser votre lingerie : ajout de strass, d’un joli nœud ou d’une initiale. Une belle idée cadeau !',
  },
  {
    title: 'Le BHV Marais - 14 déc',
    subTitle: 'Atelier création d’accessoires à l’Atelier des Experts',
    imagesHeight: 556,
    images: [imageBHVMarais1, imageBHVMarais2],
    // eslint-disable-next-line max-len
    text: 'Tilli dévoile son corner “Création d’accessoires upcyclés” à L’Atelier des Experts du BHV Marais. Choisissez parmi les tissus offerts par nos partenaires Cyrillus, Madura et Le Jacquard Français pour co-créer avec l’un de nos Tillistes votre accessoire sur-mesure et upcyclé. Des pierres Swarovski Professional sont à disposition pour ajouter une petite touche de glamour à ce dernier !',
    subText: 'Pour les futurs mariés, Tilli sera également présent le même jour au BHV pour présenter ses services.',
  },
  {
    title: 'Le Slip Français - WE du 7, 14 et 21 déc',
    subTitle: 'Ateliers brodeuses machines',
    imagesHeight: 566,
    images: [imageSlipFrancais1, imageSlipFrancais2],
    // eslint-disable-next-line max-len
    text: 'Les week-ends du 7 décembre, 14 décembre et 21 décembre, nos Tillistes sont présents dans la boutique du <link>Slip Français<link> à Sentier et au corner du Bon Marché pour personnaliser vos slips, chaussettes, t-shirts et autres achats d’une jolie broderie.',
    link: 'https://www.leslipfrancais.fr/idees-cadeaux?gclid=EAIaIQobChMI692UwbWX5gIVx4TVCh0I1QHiEAAYASAAEgLMxvD_BwE',
  },
  {
    title: 'The Socialite Family - 7 & 14 déc',
    subTitle: 'Atelier broderie main sur linge de maison',
    imagesHeight: 437,
    images: [imageTheSocialiteFamily1, imageTheSocialiteFamily2],
    // eslint-disable-next-line max-len
    text: 'Les samedis 7 et 14 décembre, nos Tillistes seront dans la boutique <link>The Socialite Family<link> pour broder à la main votre linge de maison.',
    link: 'https://www.thesocialitefamily.com/',
  },
  {
    title: 'Repair Day Adresse Paris - 29 nov',
    subTitle: 'Retouches et réparations en boutique',
    imagesHeight: 485,
    images: [imageAdresseParis1, imageAdresseParis2],
    // eslint-disable-next-line max-len
    text: 'Pour prendre le contre-pied du Black Friday, Tilli s’associe à <link>Adresse Paris<link> pour donner une seconde vie à vos vêtements et accessoires. Pendant toute la journée, un atelier de réparation est mis en place dans la boutique Adresse Paris située à deux pas de République.',
    link: 'https://adresse.paris/',
  },
  {
    title: 'Galeries Lafayette Champs Élysées - du 20 au 29 nov',
    subTitle: 'Retouches et réparations Ventes Privées',
    imagesHeight: 647,
    images: [imageGaleriesLafayetteChampsElysees1],
    // eslint-disable-next-line max-len
    text: 'Du 20 au 29 novembre, pendant les Ventes Privées des Galeries Lafayettes Champs Ēlysées, nos Tillistes vous accompagnent pour toutes les retouches et ajustements de vos vêtements et accessoires.',
  },
  {
    title: 'Printemps Mariage - 9 nov & 7 déc',
    subTitle: 'Retouches, réparations, upcycling de robe de mariée',
    imagesHeight: 555,
    images: [imagePrintempsMariage1, imagePrintempsMariage2],
    areMobileImagesReversed: true,
    // eslint-disable-next-line max-len
    text: 'Les samedis 9 novembre et 7 décembre, Tilli est aux Belles Affaires du <link>Printemps Mariage<link>. Nos Tillistes sont sur place toute la journée pour vous conseiller sur les retouches, la remise à taille ou la transformation de vos robes de mariée.',
    link: 'https://listes.printemps.com/mariage/actualites/w/braderie-belles-affaires-49021',
  },
];

const formatText = (textToFormat, link, hasSubText) => {
  const splittedText = textToFormat.split('<link>');
  return (
    <EventText hasSubText={hasSubText}>
      {splittedText.map((text, index) => {
        if (index % 2 === 0) return <span>{text}</span>;
        return <A href={link} target="_blank" rel="noopener noreferrer">{text}</A>;
      })}
    </EventText>
  );
};


const Events = () => (
  <Container>
    <Title>Les Ateliers Tilli</Title>
    <Line />
    <Text>
      Atelier broderie, transformations de robe de mariée, retouches et réparations en faveur du Green Friday,
      création d’accessoires sur-mesure... Tout au long de l’année nos Tillistes multiplient les activités en
      boutiques chez nos marques partenaires et vous dévoilent leurs différents savoir-faire.
    </Text>

    {events.map(({ title, subTitle, imagesHeight, images, text, subText, link, areMobileImagesReversed }) => (
      <EventContainer key={title}>
        <EventTitle>{title}</EventTitle>
        <EventTitle isSubTitle>{subTitle}</EventTitle>
        <EventImageContainer mobileReversed={areMobileImagesReversed}>
          {images.map((image, index) => (
            <EventImg key={image} src={image} alt={title} hasMarginLeft={index !== 0} height={imagesHeight} loading={index > 0 ? 'lazy' : 'eager'} />
          ))}
        </EventImageContainer>
        {formatText(text, link, !!subText)}
        {!!subText && <EventText isSubText>{subText}</EventText>}
      </EventContainer>
    ))}

    <Text isBottomText>
      Vous n’avez pas le temps de vous déplacer lors de nos événements.
      Découvrez notre carte cadeau : création d’accessoires,
      upcycling, personnalisation de vêtements et d’accessoires.
    </Text>

    <A href="https://cadeau.tilli.fr" target="_blank" rel="noopener">
      <Button navy>Offrir la carte cadeau</Button>
    </A>
  </Container>
);

export default Events;
